/* Remove Arrows from number input field */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}
/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Remove Arrows from number input field */

input,
select:focus {
  outline: none;
}
.w-10per {
  width: 10%;
  min-width: fit-content;
}

/* Edit dateTiem in match listing page */
.editMatchDateTime {
  border: none;
  width: 135px;
}
.editMatchDateTime:focus {
  border: 1px solid #89cff0;
}
/* Edit dateTiem in match listing page */

.form-control:focus {
  box-shadow: none !important;
}

.form-control:disabled {
  opacity: 0.5 !important;
}

.leaugepointtd {
  height: 21px;
  width: 48px;
  padding: 0px !important;
}

.leaugepointth {
  width: 48px;
  padding: 0px !important;
}

.nopadding {
  padding: 0px !important;
  width: 9%;
}
.nopaddingIce {
  padding: 0px !important;
  width: 8%;
}
.nopaddingVolleyball {
  padding: 0px !important;
  width: 5%;
}

.fdheight {
  height: 38px;
}

.nav-links {
  margin-top: -15px;
  font-weight: 400 !important;
}

.nav-links :hover {
  background-color: #000;
}
.fs-14 {
  font-size: 14px !important;
  line-height: 1.2 !important;
}
.main-content-cus {
  padding: 25px;
}
.tournamentcard {
  margin-right: 0pc;
}
.d-flex {
  display: none !important;
}
.coach-1 {
  margin-top: -10px;
  margin-left: 23px;
  color: #6666 !important;
}
.flagicon {
  margin-top: 12px !important;
  border-right: none !important;
}
strong {
  font-weight: bolder;
  margin: 0px 5px;
}

body {
  font-family: "Poppins", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.loader-style {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-shadow: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.iconflag {
  height: 35px;
  width: 35px;
  margin-top: 8px;
}
.cus-input-group {
  border: 1px solid;
  border-color: #ced4da;
  border-radius: 5px;
}

.cus-input-group .input-group-text {
  border: 0px;
  background: transparent;
}
.cus-input-group input {
  margin: 0 !important;
  border: 0px !important;
  padding-left: 0px !important;
}
.bordernone {
  border-left: none !important;
  border-right: none !important;
}
.modelview1 {
  margin-left: -5px !important;
}
.form-controlinput {
  margin-left: 10px !important;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  box-shadow: none !important;
  background-color: #fff;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: 0.15s ease-in-out, 0.15s ease-in-out;
}
.remove-shadow {
  box-shadow: none !important;
}
h5,
.h5 {
  font-size: 1.25rem;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #222;
  font-weight: 400;
  background: #f6f7fb;
  position: relative;
}
/* Make the table button small */
.btn-sm1 {
  /* height: 20px;
  width: 20px; */
  height: 22px;
  width: 28px;
  /* margin-top: 2px; */
}

.lh-1 {
  width: 30px;
}

.icontable {
  height: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -3px;
  margin-left: -5px;
}

.icontableLM {
  height: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -3px;
  margin-left: -7px;
}

.btn-sm11 {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icontable11 {
  height: 14px;
}
.icontable12 {
  padding-top: 1.5px;
  height: 19px;
}

.textdec {
  list-style: none;
  text-decoration: none !important;
}

.icontable1 {
  height: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -5px;
  margin-left: -7px;
}

/* Make the table button small */

.table td {
  /* border-top: 1px solid #e2e5e8; */
  /* padding: 15px !important; */
  white-space: nowrap;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-active-color: #262627;
  --bs-table-striped-bg: hsla(0, 1%, 44%, 0.05) !important;
  --bs-table-active-bg: hsla(0, 1%, 44%, 0.05);
  --bs-table-hover-color: hsla(0, 1%, 44%, 0.05);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.form-control1 {
  height: 37px;
}
.form-control:not(.btn-warning, .btn-success, .btn-primary, .upDelivery) {
  display: block;

  padding: 0.375rem 0.95rem !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 2px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-primary:hover {
  background-color: #028799 !important;
  border-color: #008fa1 !important;
  color: #fff !important;
}

.btn-outline-primary {
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
  color: #fff !important;
}

.card .card-header h5 {
  margin-bottom: 0;
  color: #222;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
  /* padding-top: 10px; */
}

.btn-info {
  color: #000;
  background-color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2 !important;
  border-color: #25cff2 !important;
}

#layoutSidenav {
  display: flex;
}

.btn-danger {
  color: whitesmoke !important;
  border-color: #dc3545 !important;
  background-color: #ff5370 !important;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff2d50 !important;
  border-color: #ff2046;
}

.floating-label {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 3px;
}
.btn-success {
  background-color: #2ed8b6 !important;
  border-color: #2ed8b6 !important;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 20px !important;
  font-weight: bold !important;
}
.hr {
  margin-left: -17px;
  margin-right: -15px;
  margin-top: 25px;
}
.btn-success:hover {
  background-color: #23bd9e !important;
  border-color: #21b295;
}
.btn-info:focus {
  box-shadow: none !important;
}
.btn-success:focus {
  box-shadow: none !important;
}
.btn-outline-danger:focus {
  box-shadow: none !important;
}
.btn-outline-primary:focus {
  box-shadow: none !important;
}
.btn-danger:focus {
  box-shadow: none;
}
.btn-primary:focus {
  box-shadow: none;
}
.textdecnone {
  text-decoration: none !important;
}
.sb-nav-fixed .sb-topnav {
  padding-bottom: 2px;
}

.btn {
  border: 1px solid transparent;
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

.btn-outline-danger {
  color: whitesmoke !important;
  border-color: #dc3545 !important;
  background-color: #ff5370 !important;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff2d50 !important;
  border-color: #ff2046;
}

/* .card:hover {
  box-shadow: 0 0 25px -5px #9e9c9e;
} */

thead {
  text-transform: uppercase !important;
  font-size: 14px !important;
  border-bottom: 0px #000 #dee2e6 !important;
  border-color: #dee2e6 !important;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  height: 100vh;
  width: 225px;
  z-index: 1038 !important;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0) !important;
  }
}

.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}

#layoutSidenav #layoutSidenav_nav {
  flex-basis: 225px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

div#layoutSidenav_nav {
  box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 16%);
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 56px;
}

.sb-sidenav-dark {
  background-color: #fff;
  color: #666;
}

.sb-sidenav {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}

div {
  display: block;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}

.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.justify-content-between {
  justify-content: space-between !important;
  /* margin-top: 5px; */
}

.d-flex {
  display: flex !important;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 225px;
  top: 56px;
}

#layoutSidenav #layoutSidenav_content {
  margin-left: 0;
  transition: margin 0.15s ease-in-out;
}

#layoutSidenav #layoutSidenav_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  min-height: calc(100vh - 56px);
  min-width: 0;
  position: relative;
}

.main-content-cus {
  padding: 25px;
}

.main-content-cus {
  background-color: hsla(0, 1%, 44%, 0.05);
  min-height: calc(100vh - 56px);
}

.card-cus {
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  position: relative;
  /* padding: 25px; */
  /* max-width: 1215px; */
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.col-sm-12 {
  flex: 0 0 auto;
  width: 100%;
}

.row > * {
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}

.card {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  /* border: 3px solid rgba(0, 0, 0, .125); */
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
}

card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  margin-bottom: 0;
  /* padding: 0.5rem 1rem; */
  background-color: initial !important;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125) !important;
}
.card-header {
  /* padding: 5px 1rem 0 1rem !important; */
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* @media (min-width: 992px) { */
.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
  margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  -webkit-transform: translateX(-225px) !important;
  transform: translateX(-225px) !important;
}
/* } */

.last-none li:last-child hr {
  display: none !important;
}

.col-4-grid {
  grid-template-columns: 1fr 1fr 1fr;
}

/* <a> tag styling*/
a:link,
a:visited {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
/* <a> tag styling*/

Button {
  box-shadow: none !important;
}

.container.detailContainer:not(.formShadow) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.detailContainer {
  background-color: white;
  min-width: 100%;
}

.reactSelectStyle {
  max-height: 95px !important;
  box-shadow: none;
}

/* .reactSelectStyle > div {
  min-height: auto !important;
}
.reactSelectStyle > div > div:last-child > div{
  padding: 6.5px;
} */

.reactSelectStyle > div {
  min-height: auto !important;
}
.reactSelectStyle > div > div:last-child > div {
  padding: 6.5px;
}

/* CommentaryTable1 */
.parentCommentaryTable1 {
  overflow-x: inherit;
}
/* CommentaryTable1 */

.parentCommentaryTable1 .table-responsive {
  overflow-x: inherit;
}

.iconSelectdColor {
  color: chartreuse !important;
}

.iconNotSelectdColor {
  color: white !important;
}
.css-6j8wv5-Input {
  margin: 0 !important;
  padding: 0 !important;
}
.css-1s2u09g-control {
  height: 40px !important;
  min-height: 40px important;
}
.css-319lph-ValueContainer {
  padding: 0 5px !important;
}
.form-control {
  height: 40px !important;
  padding: 0 5px !important;
  font-size: 14px !important;
}
.btn-sm.btn.btn-success {
  height: 40px;
  border-radius: 5px;
}
.btn-sm.btn.btn-primary {
  height: 40px;
  border-radius: 5px;
}
.form-group {
  position: relative;
}
.contest_form .row {
  padding-bottom: 7px;
  border-bottom: #ced4da solid 1px;
  margin-bottom: 5px;
}
.errorrequired {
  font-size: 12px !important;
  /* position: absolute; */
  bottom: -16px;
  left: 0;
}
.errorrequiredGrid {
  font-size: 12px !important;
  /* position: absolute; */
  bottom: -2px;
  left: 6px;
}
.card-body {
  padding: 0px 6px !important;
}
table tbody tr td {
  vertical-align: middle;
  padding: 5px 0.5rem;
}
.table_form tbody tr td {
  padding-bottom: 15px;
  padding-top: 15px;
  width: 25%;
}
.table_form.lineuptable tbody tr td:nth-child(1) {
  width: 31%;
}
.table_form.lineuptable tbody tr td:nth-child(2) {
  width: 31%;
}
.table_form.lineuptable tbody tr td:nth-child(3) {
  width: 13%;
}
.table_form.lineuptable tbody tr td:nth-child(4) {
  width: 15%;
}
.table_form.table_extras tbody tr td {
  width: 10%;
}
.table_form.table_table1 tbody tr td {
  width: 10%;
}

.batScoreCard .form-control {
  padding: 0px 2px !important;
  height: 30px !important;
  min-width: 10%;
}

.table_form.table_table1 {
  min-width: 1440px;
}
/* #region new css table */
.cc-lineup .mySelect__clear-indicator {
  padding: 3px !important;
}

.batScoreCard .handleFieldWidth {
  width: 134px;
}

.ballScoreCard {
  max-width: 1660px;
}
.ballScoreCard .form-control {
  padding: 0px 2px !important;
  height: 30px !important;
  min-width: 10%;
}
.ballScoreCard tbody tr:first-child td {
  padding-top: 5px !important;
}
.ballScoreCard tbody td:first-child .mySelect__single-value {
  text-align: center;
}
.ballScoreCard tbody td:first-child .mySelect__input-container {
  text-align: center;
  justify-content: center;
}
.ballScoreCard td,
.ballScoreCard th {
  padding: 1px 8px !important;
  border: 0 !important;
}
.ballScoreCard td {
  padding: 1px 8px !important;
}
.ballScoreCard td:nth-child(2),
.ballScoreCard th:nth-child(2) {
  width: 200px !important;
}

.ballScoreCard td .form-group {
  width: 80px;
}
.ballScoreCard td .form-group .form-control {
  text-align: center;
}
.ballScoreCard td .form-group.handleFieldWidth {
  width: 100%;
}
/* endregion end css table */

.table_extras .form-control {
  padding: 0px 2px !important;
  height: 30px !important;
}

.table_form.table_contest_football tbody tr td {
  width: 100%;
  max-width: 16.66%;
}
.table_form.table_matches_football tbody tr td {
  width: 16.6%;
}
.table_form.table_player_football tbody tr td {
  width: 20%;
}
.table_form.table_venue_football tbody tr td {
  width: 25%;
}
.table_form.table_copy_squad tbody tr td {
  width: 20%;
}
.react-datepicker-wrapper {
  display: block !important;
}
.table_form.filterstableColor.no-fixed {
  table-layout: auto !important;
}
.table_form.filterstableColor {
  /* table-layout: fixed; */
  width: 100%;
}
.table_form.filterstableColor tr th:first-child {
  width: 60px;
}
.table_form.filterstableColor tr th {
  /* width: 14.2% */
  width: 100%;
}

.parentCommentaryTable1.cricketmatchestable .table-responsive {
  overflow-x: scroll;
}

.parentCommentaryTable1.footballmatchestable .table-responsive {
  overflow-x: scroll;
}
.batScoreCard.table-responsive {
  overflow-x: scroll;
}

button.form-control.filter-search-btn {
  width: 100px;
}

div.card-cus {
  box-shadow: none;
}
button.form-control.filter-search-btn {
  width: 100px;
}

div.card-cus {
  box-shadow: none;
}

.filterstableColor {
  background-color: #fff;
}

section {
  padding: 0px 1rem;
  background-color: #fff;
  margin-bottom: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: fit-content;
}

section.notfirst {
  padding-top: 1rem;
}

div.formShadow {
  box-shadow: 0 0 5px 1px rgb(4 26 55 / 16%);
}

.bgred, .bgred td {
  background-color: #fd5c63 !important;
}

.bggrey td {
  background-color: #808080 !important;
}


.contest_header {
  background: hsla(0, 0%, 79.2%, 0.3);
}
.contest_header.Female>td {
  background-color: #db68e5 !important;
}
.contest_header.Mixed>td {
  background: radial-gradient(ellipse at center,  rgba(219,104,229,1) 0%,rgba(217,106,229,0.9) 2%,rgba(125,185,232,0.9) 100%) !important;
}
.contest_header.Male>td {
  background-color: #00bcd4b3 !important;
}

.btn_save_fixtures {
  height: 38px;
}
.contest_header.style_2 .patren-list{
  right: 130px;
}
.contest_header.style_2 .patren-list.male-female{
  right: 370px;
}
tr.contest_header.style_2:last-child td button{
  height: 34px;
}
.btn_save_fixtures.left {
  height: 38px;
  margin-right: 10px;
}

.checkbox_bottom_margin {
  margin-bottom: 12px;
}

.all_fixtures_contest {
  text-align: center;
  line-height: 35px;
  font-weight: bolder;
}

.badge_close_icon {
  cursor: pointer;
  margin-left: 5px;
}

button.nav-link {
  color: #495057;
}

.nav-tabs .nav-link.tab_link {
  background: hsla(0, 0%, 79.2%, 0.3);
}

ul.nav-tabs {
  margin-bottom: 0 !important;
  margin-top: 1rem;
}

.nav-tabs button.nav-link.active {
  color: #495057;
}

ul.nav {
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
}

/* handle width of react select dropdown */
/* #react-select-2-listbox{
  width: fit-content
  } */
.css-26l3qy-menu {
  width: 100%;
  min-width: 100% !important;
  min-width: max-content !important;
}
.css-4ljt47-MenuList {
  overflow-x: hidden;
  padding: 0px 5px;
}
/* handle width of react select dropdown */

/* width of bowling scorecard fields */
.handleFieldWidth {
  /* width: fit-content;
  min-width: 100% !important; */

  width: 120px;
}
.upload-image-file input {
  width: 100%;
  position: absolute;
  left: 0;
  top: 25px;
  opacity: 0;
}
.upload-image-file span {
  display: block;
  padding: 0.375rem 0.95rem !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px !important;
  height: 40px;
}
/* width of bowling scorecard fields */

/* deliveryError */
.deliveryError {
  position: relative;
  bottom: 0px !important;
}

.mw-235 input {
  max-width: 235px;
  width: 100%;
}

/* Tennis match score */
.scoreField {
  max-width: 50px;
  /* max-width: 50px; */
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
  margin: 0px 5px;
  text-align: center;
}

/* Tennis match score */

.text_padding_top {
  padding-top: 5px;
}

/* Make total matches (sticky) */
.table_contest_matchscreen tbody tr:last-child td button {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99999;
}
/* Make total matches (sticky) */

.btn_fix_styles {
  background: #005580;
  border-radius: 5px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  font-size: 10px;
  text-align: center;
  border: none;
}
.btn_fix_styles_danger {
  background: #d30909;
  border-radius: 5px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  font-size: 10px;
  text-align: center;
  border: none;
}

/* Field Array Error Border */
.form-group.fill.fieldArray:has(.errorrequired) input {
  border: 1px solid red !important;
}
/* Field Array Error Border */

/* React select height of disabled (true/false) */
.css-jzldcf-Input {
  margin: 0px !important;
}
/* React select disable (true/false) */

.customTooltip {
  padding: 4px 4px !important;
  background: black !important;
}

.match_goal_cards_subs input {
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
  max-width: 50px;
  text-align: center;
}

.tableIdWidth {
  width: 70px !important;
  min-width: fit-content;
}
.basketBallPoints {
  width: 90px !important;
  min-width: fit-content;
}
.tableDateTimeWidth {
  width: 160px !important;
  min-width: fit-content;
}

.squad_player_span {
  background-color: #2ed8b6;
  padding: 0px 5px;
  border-radius: 5px;
}

.squad_player_span a {
  color: #ffff;
}

.squad_player_span a:hover {
  opacity: 0.8;
}

.text_td {
  white-space: pre-wrap !important;
}

.comment-form-cc td {
  vertical-align: top;
}

.table.match_detail_table {
  margin-bottom: 0px;
}

.table.match_goal_cards_subs_detail {
  margin-bottom: 0px;
}

section.eqpadding4x4 {
  padding: 0.5rem;
}

.match_goal_cards_subs tbody tr td:nth-child(1) {
  width: 15%;
}

.match_goal_cards_subs tbody tr td:nth-child(2) {
  width: 15%;
}

.match_goal_cards_subs tbody tr td:nth-child(3) {
  width: 25%;
}

.match_goal_cards_subsitutions input {
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
  max-width: 50px;
  text-align: center;
}

.match_goal_cards_subsitutions tbody tr td:nth-child(1) {
  width: 15%;
}

.match_goal_cards_subsitutions tbody tr td:nth-child(2) {
  width: 15%;
}

.match_goal_cards_subsitutions tbody tr td:nth-child(5) {
  width: 15%;
}

.match_goal_cards_subsitutions tbody tr td:nth-child(6) {
  width: 15%;
}

.match_scores_table input.scoreinput {
  max-width: 50px;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
  margin: 0px 5px;
  text-align: center;
}

.match_scores_detail_section .status_select {
  width: 20%;
}

.croller_screen_tab li button.tab_link.nav-link{
  background: #fff;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.croller_screen_tab li button.tab_link.nav-link.active{
  /* background: rgb(255, 234, 234); */
  background: hsla(0, 0%, 79.2%, 0.3);
}
.provided_team_name{
  white-space: pre-wrap !important;
}
.match_list_table_res{
  width: 100%;
  height: calc(100vh - 280px);
  overflow-x: auto;
}

.match_time_input {
    max-width: 130px;
    height: 38px;
    border: 1px solid rgb(104, 99, 99);
    border-radius: 5px;
    margin-top: 2px;
    padding-left: 5px;
    font-size: 1.2rem;
}
.form_tornament_title{
  width: calc(100% - 127px);
}
.score_model{
  background: #cacaca4d;
}
.score_model *{
  margin: 5px;
}
.score_model svg{
  margin: 0;
}
.score_model button{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.score_model input[type="text"]{
  width: 50px;
  height: 25px;
  border: #ccc solid 1px;
}
.score_model label input{
  margin: 0 5px 0 0;
}
.score_model_outer{
  padding: 0 !important;
  width: 329px;
}
.score_model_outer .mapId{
  font-size: 14px;
  line-height: 18px;
}

.score_pop_up_width {
  width: 100px;
}

.sorting_header_cursor {
  cursor: pointer;
}

.provider_title {
  margin-bottom: 0;
    color: #222;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    line-height: 1.1;
    position: relative;
}


.snooker_svg {
  transform: scale(1.5);
}
.match_goal_cards_subs .mxw-100{
  max-width: 100% !important;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}

.prompt_overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.6);
}

.prompt_overlay .content {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.prompt_overlay .body {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 15%;
  width: 50%;
  background-color: #f1f1f0;
  border-radius: 15px;
  overflow: hidden;
  padding: 25px;
  box-shadow: 0 0 25px -5px #9e9c9e;
  align-items: stretch;
}

.scrapper_section {
  padding: 0;
  border: none;
  margin: 0;
}

.flex_equal {
  flex: 1;
}
.patren-list{
  margin: 0;
  list-style: none;
  position: fixed;
  right: 160px;
  bottom: 5px;
  z-index: 9999;
  display: flex;
  padding: 5px 8px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  background: buttonface;
  border: 2px solid black;
}
.patren-list.male-female{
  right: 550px;
}
.patren-list.contest{
  right: 5px;
}
.patren-list li{
  display: flex;
  align-items: center;
}
.patren-list li + li{
  margin-left: 10px;
}
.patren-color{
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.patren-title{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}


.enet {
  background-color: #c5ccff !important;
}

.flashScore {
  background-color: #f7dbfa !important;
}

.soccerWay {
  background-color: #f2a35a !important;
}

.sofaScore {
  background-color: #3498db  !important;
}

.btn_total_width {
  height: 34px;
}
.sticky_header {
  position: sticky;
  top: 56px;
  z-index: 9999;
  
}
.sticky_header::before{
  content: "";
  /* background: linear-gradient(to right, #4099ff, #73b4ff); */
  background-color: #f8f8f8;
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.modal-backdrop.show {
  z-index: 999;
}

.action_select .mySelect__menu{
  right: 0 !important;
  /* left: auto !important; */
  width: max-content;
}

.test_class {
  position: relative !important;
  height: auto !important;
  width: auto !important;
  /* overflow: visible !important; */
}

.grid-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

td.red a {
  color: red;
}
td a.bg-transparent {
  background-color: transparent;
}

td a.bg-transparent.enet {
  color: black;
}
td a.bg-transparent.flashScore {
  color: blue;
}
td a.bg-transparent.soccerWay {
  color: green;
}
td a.bg-transparent.manual {
  color: red;
}
ul.bottom-keys{
  list-style: none outside none ;
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: -50px;
  right: 0;
}
ul.bottom-keys li{
  padding: 5px 10px;
  color: #fff;
}

.scrapper_matches_container {
  max-height: calc(100vh - 405px);
  overflow-y: auto;
}
.scrapper_competitors_container_sofa {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}
.match_listing_scrapper_icon {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 5px;
}
.match_listing_scrapper_icon svg{
  width: 10px;
}
.match_listing_scrapper_icon .btn-outline-primary{
  background: #0d6efd !important;
  border-color: #0d6efd !important;
}
.small_fields_pg .match_listing_scrapper_icon .btn{
  height: 18px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

a.link_bolt_disable {
  pointer-events: none;
  opacity: 0.6;
}

.match_mapping_input {
  max-width: 80px;
  background-color: #fff !important;
}

.match_home_bold {
  font-weight: 900 !important;
}
.section_table_provider{
  padding-left: 12px;
  padding-right: 12px;
}

.competitors_scrapper_table tr[javaid]:not([javaid='']) {
  background: #D6FFD6 !important;
}

.competitors_scrapper_table td {
  background: transparent !important;
}

.btns_group{
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.scrapper_competitors_container {
  max-height: calc(100vh - 265px);
  overflow-y: auto;
}

.btn_custom_patnership {
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  margin-right: 2px;
}

.contest_table.table tr {
  position: relative;
  z-index: 1;
}
.contest_table.table tr .Female::before {
  content: "";
  left : 0;
  top : 0;
  bottom:  0;
  z-index: -1;
  width: 100%;
  position: absolute;
  background-color: #e4bfe7;
}

.contest_table.table>:not(caption)>*>*{
  background-color: transparent !important;
}

.home_color_hafv {
  background-color: #f0cc78 !important;
}

.competitor_counter_fixed {
  bottom: 5px;
  position: fixed;
  right: 50px;
  z-index: 99999;
}

.mySelect__menu, .mySelect__menu>div {
  min-width: max-content !important;
}
.golf_score_input{
  max-width: 100px;
  display: inline-block;
  vertical-align: top;
}
.golf_score_input + .golf_score_input{
  margin-left: 6px;
}
.golf_score_input input{
  width: 100%;
  height: 30px;
  border-radius: 6px;
}
.top_table_head{
  display: flex;
  align-items: center;
  border: solid #e2e5e8;
  background: #eff0f0;
  border-width: 1px 1px 0;
  padding: 8px 10px;
}
.top_table_head span{
  width: 50%;
}
.top_table_head span:last-child{
  text-align: right;
}

.input_50{
  max-width: 50px;
  display: inline-block;
  vertical-align: top;
}
.input_50 input{
  width: 100%;
  height: 30px;
  border-radius: 6px;
}

.mapped-row td{
  background-color: #D6FFD6 !important;
}
.unmapped-row td{
  background-color: #FFEAEA !important;
}

.player_sofa_name {
  text-wrap: unset !important;
}

button.position-absolute.btn-enet-rounds {
  right: 150px !important;
  margin-top: 5px;
}

tr.bg-yellow td {
  background-color: #FFFF00 !important;
}

tr.bg-red td {
  background-color: #ff000d !important;
}

.scrapper_competitors_container_sofa.enet-matches-comparison {
  max-height: calc(100vh - 140px) !important;
}

.match_goal_cards_subs_detail tr.bg-green td {
  background-color: #00ff80 !important;
}
.max_width_80{
  max-width: 80px;
  margin-right: 6px;
}
.max_width_80 input{
  width: 100%;
}
.mapping_cont_js label{
  margin-right: 6px;
  display: flex;
  align-items: center;
}
.mapping_cont_js label input{
  margin-right: 4px;
  /* background-color: #D6FFD6; */
}


tr.mapped_lineup_player td {
  background-color: #D6FFD6;
}

tr.not_mapped_lineup_player td {
  background-color: #FFEAEA;
}

.serving_player {
  color:red;
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
}
/* .row.row_cont_sw {
  width: max-content !important;
} */

.sccUp {
  width: 100%;
  position: relative;
}
.sccUp span {
  width: 100%;
  height: 26px;
  background: #fff;
  border: #ced4da solid 1px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  color: #495057;
  padding: 0 15px !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  align-items: center;
  align-content: center;
}
.sccUp input {
  z-index: 1;
  position: relative;
  opacity: 0;
}
.small_fields_pg .btn.daily_fixtures {
  height:26px !important;
  margin-left: 5px;
}

.line_up_unavailiable_players {
  height: 30px;
}
.line_up_unavailiable_players td{
  background-color: #00bcd4 !important;
}
.table_form.lineuptable.set_btn_table{
  table-layout: fixed;
}
.table_form.lineuptable.set_btn_table  tbody tr td:nth-child(2){
  width: 100px;
}
.table_form.lineuptable.set_btn_table  tbody tr td:nth-child(1),
.table_form.lineuptable.set_btn_table  tbody tr td:nth-child(3){
  width: calc(50% - 92px);
}
.table_form.lineuptable.set_btn_table  tbody tr td:nth-child(4){
  width: 83px;
}

.match_manual_statuses {
  display: flex;
  gap:  3px;
}
.match_manual_statuses .btn-sm{
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.nav-tabs button.nav-link.active {
  background-color: #00ff80 !important;
}