nav.sb-topnav {
    color: rgba(255, 255, 255, 0.8);
}

nav.sb-topnav.sb-topnav_stg{
    background: radial-gradient(ellipse at center,  rgba(219,104,229,1) 0%,rgba(217,106,229,0.9) 2%,rgba(125,185,232,0.9) 100%)
    !important;
}

nav.sb-topnav.sb-topnav_pr {
    background: linear-gradient(to right, #4099ff, #73b4ff) !important;
}

img.admin-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
button#dropdown-item-button {
    background: transparent;
    border: none;
}
button#dropdown-item-button:focus{
    outline: none;
    box-shadow: none;
}