div#layoutSidenav_nav{
    box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 16%);
    transition: all 0.05s ease-in-out;
}
.my-custom-dropdown .dropdown-toggle::after {
    display: none !important;
    visibility: visible;
}
div#layoutSidenav_nav {
    box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 16%);
    transition: all 0.05s ease-in-out;
    height: 100vh;
    width: 225px;
  }
.btn-sm {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.linknav{
    text-decoration: none !important;
    color: #666;
    list-style-type: none !important;
}
.listitem11{
    position: relative;
    text-decoration: none !important;
    display: list-item;
    text-align: left;
    padding: 5px 7px 5px 30px;
    background-color: transparent;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
}
.dropdownitem{
    text-decoration: none !important;
    background-color: transparent;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    display: list-item;
    position: relative;
    color: #666;
}

.dropdownsub{
    text-decoration: none !important;
    background-color: transparent;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    display: list-item;
    position: relative;
    color: #666;
}
.listitem11 :hover{
    /* color: #666; */
    color: #4099ff;
}
.listitem11:before {
    content: "";
    width: 22px;
    height: 1px;
    background-color: rgba(202, 202, 202, 0.527);
    position: absolute;
    left: 0;
    top: 15px;
}
.list{
    list-style-type: none;
    margin-top: 10px;
    margin-left: 10px;
}
.navbar11{
    /* margin-top: 6px; */
    border-left:transparent solid 4px;
}
.activeBg {
    background: hsla(0,0%,79.2%,.3);
    border-left:#4099ff solid 4px; 
 }
.activeBg1{
    font-weight: bolder;
    font-weight: 900 !important;
}
.navbar11:hover{
    cursor: pointer;
}
.sb-nav-fixed .sb-topnav{
    z-index: 1039;
    margin-top: -6px;
}
.insideDive{
    border-left: 1px solid rgba(202, 202, 202, 0.527);
    margin-left: -33px;
}
.imgnav{
    width: 15px;
    height: 15px;
    margin-right: 14px;
    transition: 0.09s linear  !important;
}
.imgnav1{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: -8px;
    transition: 0.09s linear  !important;
}
.heading{
    padding-left: 30px;
    color: #666;
    font-family: "Poppins",sans-serif;
    padding: 13px 0px 13px 13px;
    font-size: small;
    font-weight: bold;
    position: relative;
    font-size: 15px;
}
h5.heading svg {
    width: 17px;
    height: 17px;
}
.my-custom-dropdown1 {
    margin-left: -20px;
}
