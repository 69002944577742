.button123 {
    width: 28px;
    height: 22px;
    padding: 0px !important;
}
.img123 {
   width: 18px;
   height: 18px;
   margin-bottom: 5px;
}

.btnIconEye{
    margin-bottom: 9px !important;
}

.customTooltip {
    padding: 4px 4px !important;
    background: black !important;
  }