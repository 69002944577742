.small_fields_pg .form-control{
    height: 26px !important;
}
.table-bordered .small_fields_pg .mySelect__control{
    border: none;
}
.small_fields_pg .mySelect__control{
    height: 26px !important;
    min-height: 26px;
    border-radius: 2px;
}
.small_fields_pg .mySelect__dropdown-indicator{
    padding: 0 !important;
}
.small_fields_pg .mySelect__value-container{
    height: 18px !important;
}
.small_fields_pg .cc-search-filter>div{
    padding: 2px 6px 4px;
}
.small_fields_pg .mySelect__menu-list >div{
    padding: 0 6px;
    font-size: 13px;
}
.small_fields_pg .mySelect__placeholder,
.small_fields_pg .mySelect__multi-value,
.small_fields_pg .mySelect__single-value,
.small_fields_pg .mySelect__input-container{
    font-size: 13px;
    line-height: 15px;
    margin: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.small_fields_pg .mySelect__indicator{
    padding: 0 !important;
}
.small_fields_pg .btn{
    height: 22px !important;
    font-size: 12px;
    line-height: 15px;
    border-radius: 3px !important;
}
.table-bordered tr td.total-count {
    height: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.small_fields_pg table th,
.small_fields_pg table td{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
}
.small_fields_pg .card .card-header{
    padding-top: 4px;
    padding-bottom: 4px;
}
.small_fields_pg .card .card-header h5{
    font-size: 13px;
    line-height: 15px;
}
.small_fields_pg .card-header .mt-2{
    margin-top: 0 !important;
}
.small_fields_pg .card-header .btn{
    margin-top: 0 !important;
}
.table.table-bordered.filterstableColor.table_contest_matchscreen {
    margin: 0 0 5px 0;
}
.card-header>.row>.d-flex {
    padding: 0px 5px;
}
.small_fields_pg a .btn, .small_fields_pg .btn-icon{
    height: 22px !important;
}

.small_fields_pg a .btn.has-ripple{
    height: 26px !important;
}
.small_fields_pg .mySelect__value-container--is-multi{
    scrollbar-width: thin;
}
