.mainHeading{
    text-transform: uppercase;
    font-weight: 550
}
.mainHeading1{
    text-transform: uppercase;
    font-weight: 550
}
.childLabel{
    text-transform: uppercase;
    font-size: 14px;
}

.detailTableHeading{
    /* margin-bottom: 0; */
    color: #222;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    padding-top: 10px;
}
.playerStatsics th, .playerStatsics td{
    padding: 10px;
}